.cursor {
  opacity: 0;
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  pointer-events: none;
  width: 37px;
  height: 100px;
  border-radius: 50%;
  transition: opacity .35s ease-in-out;
  animation: pulse 1.5s ease-in-out infinite;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 119px;
    height: 119px;
    border-radius: 50%;
    background-color: #9945ff44;
    filter: blur(28px);
  }

  &:before {
    position: absolute;
    top: 10%;
    right: 0;
    content: '';
    width: 119px;
    height: 119px;
    border-radius: 50%;
    background-color: #9945ff80;
    filter: blur(28px);
  }
}

.hovered {
  opacity: 1 !important;
}

.button {
  position: relative;
}

@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1) translate(-50%, -50%);
  }

  50% {
    transform: scale3d(1.15, 1.15, 1.15) translate(-50%, -50%);
  }

  100% {
    transform: scale3d(1, 1, 1) translate(-50%, -50%);
  }
}
