.tilt {
  overflow: hidden !important;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-image: url(../../images/stacks/icon.svg);
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.75);
  width: 119px;
  height: 119px;
  border-radius: 30px;

  p {
    opacity: 1;
    font-size: 12px;
    position: absolute;
    bottom: 16px;
    color: #ffffff;
  }

  img {
    max-height: 50px;
    height: auto;
  }
}

@media (max-width: 992px) {
  .tilt {
    width: 160px;
    height: 160px;
    border-radius: 37px;

    p {
      font-size: 16px;
      bottom: 16px;
    }

    img {
      max-height: 70px;
      height: auto;
    }
  }
}

@media (max-width: 768px) {
  .tilt {
    width: 80px;
    height: 80px;
    border-radius: 16px;

    p {
      font-size: 7px;
      bottom: 7px;
    }

    img {
      max-height: 30px;
      height: auto;
    }
  }
}
