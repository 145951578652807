.magnetic-button {
  border: none;
  padding: 0;
  background: transparent;
  position: relative;
  z-index: 1;
  touch-action: none;
  position: absolute;

  span {
    display: inline-block;
  }

  &--hover {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.magnetic {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3vw;
  height: 3vw;

  &--redux {
    top: 40%;
    right: 10%;
  }

  &--jest {
    bottom: 55%;
    right: 35%;
  }

  &--restapi {
    top: 10%;
    left: 30%;
  }

  &--graphql {
    left: -10%;
    bottom: 25%;
  }

  &--context {
    bottom: 40%;
    left: 30%;
  }

  &--express {
    left: 0%;
    bottom: 50%;
  }

  &--typeorm {
    left: 10%;
    top: 15%;
  }

  &--firebase {
    right: 45%;
    top: 30%;
  }

  &--apolloserver {
    right: 15%;
    bottom: 35%;
  }

  &--sass {
    right: 15%;
    top: 10%;
  }

  &--gsap {
    left: 10%;
    top: 0%;
  }
}

@media (max-width: 992px) {
  .magnetic {

    &--jest {
      top: 35%;
      left: 45%;
    }

    &--restapi {
      top: 20%;
      left: 0%;
    }

    &--redux {
      top: 45%;
      right: 20%;
    }

    &--context {
      bottom: 40%;
      left: 50%;
    }

    &--firebase {
      right: 80%;
      top: 20%;
    }

    &--apolloserver {
      right: 30%;
      bottom: 45%;
    }

    &--gsap {
      left: 10%;
      top: 70%;
    }

    &--sass {
      left: 30%;
      top: 55%;
    }
  }
}
