.stacks {
  height: 100vh;
  background-color: transparent;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  background-color: #000;
  margin-top: -12vh;
  margin-bottom: 10vh;

  &__background {
    position: absolute;
    top: -25vw;
    right: 0;
    z-index: -2;
    height: 35vw;
  }

  &__gradient-header {
    position: absolute;
    border-radius: 50%;

    &--1 {
      top: -12vw;
      right: -5vw;
      width: 15vw;
      height: 10vw;
      filter: blur(2vw);
      background-color: #200E34;
    }

    &--2 {
      top: -4vw;
      right: 8vw;
      width: 22vw;
      height: 22vw;
      filter: blur(3.5vw);
      background-color: #2D144A;
    }
  }

  &__container {
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 1124px;
    background-color: #000;
    border-top: 1px solid rgb(108 106 129/1);
    border-left: 1px solid rgb(108 106 129/1);
    border-right: 1px solid rgb(108 106 129/1);
    border-radius: 60px 60px 0px 0px;
  }

  &__gradient {
    width: 600px;
    height: 200px;
    transform: rotate(20deg);
    border-radius: 50%;
    background-color: #271d3b;
    filter: blur(100px);
    top: 30%;
    right: 0;
    position: absolute;
  }

  &__content {
    width: 100%;
    max-width: 1200px;
    background-color: #000;
    height: 95%;
    position: absolute;
    z-index: 1;
    bottom: 0;
  }

  &__title {
    color: #fff;
    font-size: 89px;
    line-height: 89px;
    font-weight: 700;
    padding-top: 67px;
    margin: 0;
    position: relative;
    z-index: 99;

    &-gradient {
      bottom: -100%;
      left: 0%;
      position: absolute;
      z-index: -1;
      width: 150px;
      height: 400px;
      transform: rotate(-35deg);
      border-radius: 50%;
      background-color: #271d3b;
      filter: blur(50px);
    }
  }

  &__wrapper {
    width: 100%;
    max-width: 1200px;
    top: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    right: 0;
    z-index: 1;
  }

  &__card {
    position: absolute;

    &--back-end {
      top: 0;
      left: 0;
      z-index: 1;
    }

    &--front-end {
      top: 45px;
      left: 200px;
      z-index: 2;
    }

    &--design {
      top: 90px;
      left: 400px;
      z-index: 3;
    }

    &-title {
      color: #000;
      padding: 1rem 28px;
      border-radius: 9999px;

      &--back-end {
        background: rgb(20 241 149);
      }

      &--front-end {
        background: rgb(20 241 149);
      }

      &--design {
        background: rgb(20 241 149);
      }
    }

    &-content {
      width: 700px;
      height: 370px;
      padding: 50px 37px 37px 37px;
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: 30px;
      box-shadow: 0px 46px 64px -28px rgba(0, 0, 0, 1);
      overflow: hidden;
      background-color: #1B1622;
      border-bottom: 1px solid rgb(137 248 202);

      h3 {
        z-index: 1;
        position: relative;
        color: #000;
        font-size: 28px;
        text-align: left;
        margin: 0;
        margin-bottom: 40px;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }

  &__hover-me {
    background-color: #70eeba;
    color: #000000;
    text-transform: uppercase;
    letter-spacing: -0.8px;
    font-weight: 600;
    padding: 3px;
    font-size: 12px;
    width: 100px;
    border-radius: 7px;
    position: absolute;
    top: -55%;

    &::after {
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translate(-50%) rotate(45deg);
      z-index: -1;
      content: '';
      width: 10px;
      height: 10px;
      background-color: #70eeba;
    }
  }

  &__logos {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    top: 89px;
    left: 89px;

    &-content {
      position: relative;
      height: 400px;
      width: 500px;
      margin-top: 28px;
      margin-left: 89px;
    }
  }

  &__skill {
    position: absolute;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--reactjs {
      right: 10%;
      top: 0%;
    }

    &--react-router {
      right: 30%;
      bottom: 15%;
    }

    &--graphql {
      top: 25%;
      left: 0%;
    }

    &--nodejs {
      top: 0%;
      right: 10%;
    }

    &--sql {
      bottom: 15%;
      right: 40%;
    }

    &--rubyonrails {
      top: 15%;
      left: 5%;
    }

    &--figma {
      top: 40%;
      right: 25%;
    }

    &--styled-components {
      top: 25%;
      left: 10%;
    }

    &--antdesign {
      top: -10%;
      right: 40%;
    }

    &-content {
      width: 67px;
      height: 67px;
      border-radius: 16px;
      transition: 240ms;
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      background-image: url(../../images/stacks/icon.svg);
      background-size: cover;
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.75);
      color: #eeeeee;

      img {
        max-height: 28px;
        max-width: 28px;
        transition: 200ms;
      }

      p {
        opacity: 0;
        font-size: 12px;
        position: absolute;
        bottom: 9px;
        transition: 200ms;
      }

      &:hover {
        width: 89px;
        height: 89px;
        border-radius: 21px;

        img {
          max-height: 37px;
          max-width: 37px;
        }

        p {
          opacity: 1;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .stacks {
    &__content {
      max-width: 992px;
    }

    &__card {
      &--back-end {
        top: 0;
        left: 0;
        z-index: 1;
      }

      &--front-end {
        top: 45px;
        left: 100px;
        z-index: 2;
      }

      &--design {
        top: 90px;
        left: 200px;
        z-index: 3;
      }
    }

    &__wrapper {
      max-width: 992px;
    }
  }
}

@media (max-width: 992px) {
  .stacks {
    height: 115vh !important;
    overflow: hidden;

    &__gradient-header {
      background-color: transparent;
    }

    &__content {
      max-width: 768px;
    }

    &__wrapper {
      max-width: 768px;
    }

    &__title {
      font-size: 67px;
      line-height: 67px;
      padding-top: 50px;
    }

    &__logos-content {
      height: 700px;
      width: 500px;
      margin-top: 21px;
      margin-left: 28px;
    }

    &__skill {
      &--reactjs {
        right: 20%;
        top: 5%;
      }

      &--react-router {
        left: 10%;
        bottom: 10%;
      }

      &--graphql {
        top: 40%;
        left: 0%;
      }

      &--rubyonrails {
        top: 40%;
        left: 0%;
      }

      &--nodejs {
        top: 10%;
        right: 15%;
      }

      &--sql {
        bottom: 10%;
        right: 20%;
      }

      &--figma {
        top: 55%;
        right: 15%;
      }

      &--styled-components {
        top: 25%;
        left: 0%;
      }

      &--antdesign {
        top: 10%;
        right: 20%;
      }

      &-content {
        width: 89px;
        height: 89px;
        border-radius: 3vw;

        img {
          max-height: 5vw;
          max-width: 7vw;
        }

        p {
          font-size: 1.2vw;
          bottom: 0.3vw;
        }

        &:hover {
          width: 6.5vw;
          height: 6.5vw;

          img {
            max-height: 2.5vw;
            max-width: 3.5vw;
          }

          p {
            opacity: 1;
          }
        }
      }
    }

    &__card {
      &-content {
        width: 55vw;
        height: 80vw;
        border-radius: 3.5vw;
        padding: 6vw;
      }

      &--back-end {
        top: 0;
        left: 0;
        z-index: 1;
      }

      &--front-end {
        top: 45px;
        left: 50px;
        z-index: 2;
      }

      &--design {
        top: 90px;
        left: 100px;
        z-index: 3;
      }
    }
  }
}

@media (max-width: 768px) {
  .stacks {
    height: 100vh;

    &__title {
      padding-left: 28px;
      font-size: 37px;
      line-height: 37px;

      &-gradient {
        bottom: 30%;
        left: 5%;
        position: absolute;
        z-index: -1;
        width: 100px;
        height: 40px;
        border-radius: 50%;
        background-color: #2d345fbb;
        filter: blur(10px);
      }
    }

    &__content {
      max-width: 768px;
    }

    &__wrapper {
      max-width: 768px;
      top: 250px;
      margin-left: 28px;
    }

    &__logos-content {
      height: 700px;
      width: 500px;
      margin-top: 21px;
      margin-left: 28px;
    }

    &__skill {
      &--reactjs {
        right: 20%;
        top: 5%;
      }

      &--gsap {
        right: 20%;
        bottom: 0%;
      }

      &--graphql {
        top: 40%;
        left: 0%;
      }

      &--rubyonrails {
        top: 40%;
        left: 0%;
      }

      &--nodejs {
        top: 10%;
        right: 15%;
      }

      &--figma {
        top: 55%;
        right: 15%;
      }

      &--materialdesign {
        top: 40%;
        left: 0%;
      }

      &--antdesign {
        top: 10%;
        right: 20%;
      }

      &-content {
        width: 12vw;
        height: 12vw;
        border-radius: 3vw;

        img {
          max-height: 5vw;
          max-width: 7vw;
        }

        p {
          font-size: 1.2vw;
          bottom: 0.3vw;
        }

        &:hover {
          width: 6.5vw;
          height: 6.5vw;

          img {
            max-height: 2.5vw;
            max-width: 3.5vw;
          }

          p {
            opacity: 1;
          }
        }
      }
    }

    &__logos {
      display: flex;
      justify-content: center;
      position: absolute;
      width: 100%;
      top: 28px;
      left: 28px;

      &-content {
        position: relative;
        height: 400px;
        width: 600px;
        margin-top: 28px;
      }
    }

    &__card {


      &--back-end {
        top: 0;
        left: 0;
      }

      &--front-end {
        top: 50px;
        left: 25px;
      }

      &--design {
        top: 100px;
        left: 50px;
      }

      &-content {
        width: 200px;
        height: 370px;
        padding: 37px;
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
        box-shadow: 0px 46px 64px -28px rgba(0, 0, 0, 1);
        overflow: hidden;
        background-color: #1B1622;

        h3 {
          font-size: 21px;

          span {
            padding: 5px 15px;
          }
        }
      }
    }
  }
}
