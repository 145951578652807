.wrapper {
  background-color: #000000;
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  z-index: 99;
}

.navbar {
  height: 70px;
  display: flex;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  transition: max-height 0.7s;

  &__container {
    display: flex;
    align-items: center;
    z-index: 99;
  }

  &__link {
    text-decoration: none;

    &--name {
      display: flex;
      align-items: center;
      gap: 4px;

      img {
        width: 35px;
        height: auto;
        transform: scaleX(-1);
      }
    }
  }

  &__name {
    font-size: 28px;
    font-weight: 700;
    color: var(--color-white);
    margin-right: 40px;
    white-space: nowrap;
  }

  &__list {
    display: flex;
    gap: 21px;
    align-items: center;
  }

  &__text {
    color: #ffffff;
    border-radius: 9999px;
    transition: background-color 0.2s;
    background-color: transparent;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 600;

    &:hover {
      color: #42ba96;
    }

    &--language {
      text-decoration: none;
      border: 1px solid #19FB9B;
      background-color: #19FB9B;
      z-index: 999;
      position: relative;
      cursor: pointer;
      color: #000000;
      font-weight: 600;
    }
  }

  .burger {
    align-items: center;
    display: none;
    z-index: 99;
    outline: none;
    height: 20px;
    width: 15px;
    border: 0px;
    padding: 0px;
    background: transparent;
    transition: all 250ms ease-out;
    cursor: pointer;
  }

  .burger:before,
  .burger:after {
    content: '';
    width: 21px;
    height: 2px;
    position: absolute;
    background: var(--color-white);
    transition: all 250ms ease-out;
    will-change: transform;
  }

  .burger:before {
    transform: translateY(-3px);
  }

  .burger:after {
    transform: translateY(3px);
  }

  .active.burger:before {
    transform: translateY(0) rotate(45deg);
  }

  .active.burger:after {
    transform: translateY(0) rotate(-45deg);
  }

  .footer__legend-list {
    display: none;
  }
}

@media (max-width: 1200px) {
  .navbar {
    max-width: 992px;
  }
}

@media (max-width: 992px) {
  .navbar {
    align-items: start;
    flex-direction: column;
    justify-content: start;
    display: flex;
    transition: height 0.3s ease-in-out;
    width: 100%;
    background-color: #000000;

    &__name--footer {
      margin-right: 0;
    }

    .button__text {
      font-size: 28px;
    }

    .footer__legend-list {
      display: flex;
      margin-top: 16px;
    }

    .footer__legend-link img {
      width: 50px;
      height: 50px;
    }
  }

  .navbar__link--name {
    padding: 16px 0 0 21px;
  }

  .navbar__list {
    align-items: start;
    padding: 0 0 28px 28px;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.2s ease-in-out, transform 0.5s;
    margin: 28px 0;
  }

  .active.navbar {
    height: 450px;
  }

  .active.navbar__list {
    transform: translateY(0px);
    display: flex;
    flex-direction: column;
    opacity: 1;
  }

  .navbar__text {
    font-size: 37px;

    &:hover {
      background-color: transparent;
      color: var(--color-white-opacity-80);
    }
  }

  .active.navbar__container {
    flex-direction: column-reverse;
    align-items: start;
  }

  .navbar__text--language {
    display: none;
  }

  .navbar .burger {
    align-items: center;
    display: none;
    z-index: 99;
    outline: none;
    height: 20px;
    width: 15px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 28px;
    border: 0px;
    padding: 0px;
    background: transparent;
    transition: all 250ms ease-out;
    cursor: pointer;
  }

  .navbar {
    .burger {
      display: flex;
    }
  }
}
